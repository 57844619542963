.leadership-blocks {
    width: 100%;
    h1 {
        @include pl(1,1);
        margin-bottom: $vertical-padding;
    }
    .flex-block {
        @extend .blocks;
        a {
            // override some .blocks stuff
            @include hover-arrow(h4, 20px, 8px, 40%);//$el, $length, $arrowSize, $bottom ,$color
            flex: 0 33%;
            border: 1px solid white;
            @media  #{$phone} {
                flex: 0 100%;
                height: 55vw;
            }
        }
        .image {
            // override some .blocks stuff
            &:before {
                display: none;
            }
            &:after, .text {
                opacity: 0;
                @include transition(opacity 350ms $outQuad);
            }
            &:hover {
                &:after, .text {
                    opacity: 1;
                }
            }
        }
    }
    @media #{$phone}{
        h1 {
            margin-bottom: $vertical-padding-small;
        }
    }
}



.team {
    @include pl(1,1);
    @include pr(1,1);
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding-large;

    &.leadership-team {
        padding-bottom: $vertical-padding;
    }

    .flex-block {
        @include flex(flex,row,space-between,flex-start);
        flex-wrap: wrap;
    }
    
    .team-block {
        border-bottom: 1px solid $borderGray;
        margin-bottom: 25px;
        flex: 0 0 calc(25% - 10px);
        margin: 5px;
        a{
            .bold{color: $copy;}
            display: inline-block;
            @include hover-arrow('.first-p', 20px, 6px, 72%, red);//$el, $length, $arrowSize, $bottom ,$color
            line-height: 1;
            margin-bottom: 0px;

            .first-p {
                &:after, &:before {
                    opacity:1 !important;
                }
                &:after {
                    width: 20px;
                    background-color: $gray;
                    @include transform(translate3D(0, 50%, 0));
                }
                &:before {
                    border-left: 2px solid $gray !important;
                    border-top: 2px solid $gray !important;
                    @include transform(translate3D(0, 50%, 0) rotate(135deg) scale(1,1) );
                }
            }
            &:hover{
                .bold{color:$red;}
                .first-p {
                    &:after{
                        background-color: $red;
                    }
                    &:before{
                        border-left: 2px solid $red !important;
                        border-top: 2px solid $red !important;
                    }
                }
            }
        }
        .position {
            line-height: 1;
            font-size: $p-FS;
            margin-bottom: 15px;
        }
    }

    h1 {
        margin-bottom: $vertical-padding;
    }

    @media #{$phone}{
        @include pl(1,0);
        @include pr(1,0);
        .team-block {
          flex: 0 100%;
        }
        h1 {
            margin-bottom: $vertical-padding-small;
        }
    }
}
.gradient-title-text {
    @include flex(flex,column,center,center);
    padding-top: $vertical-padding-large;
    padding-bottom: $vertical-padding-large;
    @include pl(2,2);
    @include pr(2,2);
    text-align: center;
    font-family: $barlow;

    
    h1 {
        background: linear-gradient(340.93deg, $red 20%, #152849 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip:text ;
        -moz-text-fill-color: transparent;
        font-size: 70px;

    }
    &.fallback-text {
       h1 {
            width: 100%;
            background: transparent;
            color: $red; 
        }
    }
    // svg {
    //     width: 100%;
    //     height: 100%;
    //     tspan, text {
    //         font-family: $barlow;
    //         font-size: 65px;
    //         font-weight: 700;
    //     }   
    // }

    a {
        margin-top: $button-spacing;
    }
    @media #{$tablet}{
        @include pl(1,1);
        @include pr(1,1);
    }

    @media #{$phone} {
        @include pl(1,1);
        @include pr(1,1);
        padding-top: $vertical-padding;
        padding-bottom: $vertical-padding;
        h1{
            font-size: 35px;
        }
    }
    @media #{$tablet} {
        h1 {
            font-size: 50px;
        }
    }

    @media #{$full} {
        h1 {
            font-size: 65px;
        }
    }
}

.about {
    .gradient-title-text {
        text-align: left;
        @include pl(1,1);
        @include pr(3,1);
        padding-top: $vertical-padding;
        padding-bottom: $vertical-padding-large;
    }
    @media #{$phone} {
        .gradient-title-text {
            @include pl(1,0);
            @include pr(1,0);
            padding-bottom: $vertical-padding-small;
        }

    }

    
}
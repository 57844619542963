.contact {
    &:before{
        content:'';
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        // @extend .maxWidth;
        background: $redBlueGradient;
        background-repeat: no-repeat;
        background-attachment: fixed;
        z-index: -1;
        // @media #{$max}{
        //     left: calc(( 100vw - #{$maxWidth})/2)
        // }
    }

    .contact-content {
        padding-top: $vertical-padding;
        padding-bottom: $vertical-padding-large;
        @include pl(1,2);
        @include pr(1,2);
        min-height: calc(100vh - #{$nav-height});
        .info {
            padding-top: $vertical-padding-large;
            @include flex(flex,row,flex-start,space-between);
            flex-wrap: wrap;
        }

        .emails {
            width: 60%;
            @include flex(flex,row,center,space-between);
            flex-wrap: wrap;

            .email-item{
                flex: 0 50%;
                margin-bottom: 30px;

                a {
                    position: relative;
                    @include hover-arrow('&', 20px, 8px, 35%, white);
                }
                @media  #{$phone} {
                    flex: 0 100%;
                }
            }
            @media  #{$mobile} {
                width: 100%;
                padding-top: $vertical-padding;
            }
        }
        .address {
            width: 40%;
            @media  #{$phone} {
                width: 100%;
            }
            .address-item {
                &:not(:nth-child(1)){
                    padding-top: $vertical-padding;
                }
            }
            pre {
                margin: 0;
            }
        }
    }
    @media #{$phone}{
        .contact-content {
            min-height: calc(100vh - #{$nav-height-mobile});
        }
    }
}
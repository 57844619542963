.summary-text {
	&.alone {
		width: 100%;
	}
	@include width(7, 6);

	p {
		@extend .text-m;
		&:not(:last-child){
			margin-bottom: 25px;
		}
	}

	&.legal {
		h1 {
			line-height: 1.3;
			margin-bottom: 30px;
		}
		h2,
		h3,
		h4 {
			margin-bottom: 0px;
		}
		p {
			font-size: $p-FS;
			margin-bottom: 0px;
			margin-top: 15px;
			line-height: $p-LH;
		}
		a {
			color: $red;
			&:hover {
				color: $copyGray;
			}
		}
		ul {
			padding-left: 15px;
			font-family: $barlow-cond;
			font-weight: 400;
			li {
				list-style: disc;
				font-size: $p-FS;
				margin-bottom: 0px;
				margin-top: 15px;
				line-height: 1.5;
			}
		}
	}

	@media #{$mobile} {
		width: 100%;
		padding: 0;
	}
	@media #{$tablet} {
		width: 100%;
		padding: 0;
	}
	@media #{$phone}{
		margin-bottom: $vertical-padding-small;
	}
}

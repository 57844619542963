.press-kit {
   padding-top: $vertical-padding-large;
   padding-bottom: $vertical-padding-large;
   width: 100%;
   text-align: center;

   .button {
      margin-top: 50px;
      display: inline-block;
      margin: $vertical-padding-small 10px $vertical-padding;
   }

   h5 {
      margin: 0;
      font-size: 32px;
   }
   p {
      margin-bottom: 0px;
   }
   .flickity-viewport,
   .flickity-slider {
      height: 65vh !important;
   }

   .slider {
      position: relative;
      overflow: hidden;
      height: 65vh;
      outline: none;
      padding-left: $gutter;
      padding-right: $gutter;
      .flickity-page-dots {
         display: none;
      }
      .flickity-prev-next-button {
         position: absolute;
         opacity: 1;
         height: 100%;
         border: none;
         top: 0;
         //ARROW
         $width: 25px;
         $arrowHead: 9px;
         cursor: pointer;
         &.disabled {
            display: none;
         }
         &:after,
         &:before {
            top: 50%;
         }
         &:after {
            content: "";
            background-color: white;
            position: absolute;
            @include transform(translate3D(0, 50%, 0));
            @include transform-origin(50%, 0%);
            margin-left: 6px;
            opacity: 1;
            width: $width;
            height: 2px;
            left: 0;
         }

         &:before {
            content: "";
            position: absolute;
            @include transform(
               translate3D(0, 50%, 0) rotate(135deg) scale(1, 1)
            );
            background-color: transparent;
            border-left: 2px solid white;
            border-top: 2px solid white;
            opacity: 1;
            left: calc(#{$width} - 4px);
            width: $arrowHead;
            height: $arrowHead;
            top: calc(50% - 9px);
         }

         svg {
            display: none;
         }

         &.previous {
            &:before {
               @include transform(
                  translate3D(0, 50%, 0) rotate(310deg) scale(1, 1)
               );
               left: auto;
               right: calc(#{$width} - 9px);
            }
            &:after {
               right: 0;
               left: auto;
            }
         }
      }

      .slide {
         margin: $gutter/2 15px;
         height: 100%;
         img {
            height: 90%;
            width: auto;
         }
      }
   }

   @media #{$mobile} {
      h5,
      p {
         @include pl(1, 0);
         @include pr(1, 0);
      }

      .slider .slide {
         height: 100%;
         width: 100vw;
         margin: 0;
         @include flex(flex, row, center, center);
         img {
            height: auto;
            width: 100%;
         }
      }
   }
}

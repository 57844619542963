.image-page-header {
    height: calc(100vw * .5625);
    width: 100%;
    margin-bottom: $vertical-padding;
    // max-height: 700px;
    
    .image {
        height: 100%;
        width: 100%;
        position: relative;
        @include full-bk(center top, cover);
        @extend .gradient;
        @include flex(flex,row,center,center);

        .text {
            @include pl(1,1);
            @include width(10,11);
            position: absolute;
            left:0;
            bottom: 25px;
            z-index: map-get($z-index,sliderText);

            a:hover {
                color: $red;
            }
        }
        .scroll-text{
            @include pr(1,1);
            position: absolute;
            bottom: 25px;
            right: 0px;
            z-index: map-get($z-index,sliderText);
            span{
                @extend .meta-title;
                color: rgba( white, .5 );
            }
            @media #{$phone}{
                left: 0px;
                @include pl(1,1);
                right: auto;
                bottom: 10px;

            }
        }

        .play-button {
            width: 120px;
            height: 120px;      
            z-index:50;
        }
    }
    @media #{$max}{
        height: calc(#{$maxWidth} * .5625);
    }
    @media #{$phone}{
        margin-bottom:$vertical-padding-small;
        .image {
            .text {
                @include pl(1,0);
                @include width(10,13);
                bottom: 15px;
            }
            .play-button {
                width: 80px;
                height: 80px;      
            }
        }
        
    }
}

.video-modal {
    height: 100vh; 
    min-height: 100vh;
    @include flex(flex,row,center,center);
    margin-top: 0;
    .contents {
        width:100%;
        // height: 100vh;
        // @include flex(flex,row,center,center);
    }
}
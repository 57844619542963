// ---------------------------------------------
// Modal
// ---------------------------------------------

.modal {
    @include width(8, 9);
    margin: $vertical-padding-large auto;


    @media #{$phone}{
        @include width(12, 9);
        margin: $vertical-padding auto;
    }
}

.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    overflow: scroll;
    width: 100%;
    min-height: 100vh;
    z-index: map-get($z-index, modal);

    @include transition(opacity 350ms 0ms ease);

    &.closed {
        z-index: map-get($z-index, hidden);
        opacity: 0;
        @include transition(opacity 350ms 0ms ease, z-index 0ms 350ms);
    }
    .close-button {
        position: fixed;
        right: $gutter;
        top: $gutter;
    } 
    
    &-leadership {
        background: $redBlueGradient;
    }
    &-video {
        background: $blueGradient;
    }
    @media #{$phone}{
        .close-button {
            height: 30px;
            position: absolute;
            svg{
                height: 30px;
            }
        } 
    }
}
.meta-info {
    @include width(4,4);
    .info-block {
        padding-bottom: 30px;
        ul {
            &.two-column-1, &.logo-ul {
                columns: 2;
                column-width: 50%;
            }
            
            li {
                font-family:$barlow-cond;
                font-size: $p-FS;

                a {
                    color: $copy;

                    &:hover {
                        color:red;
                    }
                }

                img {
                    height: 30px;
                    margin-top: 20px;
                }
            }
        }
        &.half-width-1 {
            width:50%;
            float:left;
        }
    }
    .button {
        display: inline-block;
        margin-bottom: $button-spacing;
        &:nth-of-type(2 ){
            margin-left: 15px;
        }
    }

    .social {
        li {
            display:inline-block;
            margin-right: 15px;

            a {
                @include transition(transform 250ms);
                &:hover {
                    svg { 
                        @include transform(scale(1.1));
                    }
                }
            }
        }
    }
    @media #{$tablet}{
        width:100%;
        .button {
            &:nth-of-type(2){margin-left: 0;}
        }
    }

    @media #{$phone} {
       width: 100%;
    }
}
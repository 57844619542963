
.where-to-watch-streaming {
	&:before{
      content:'';
      position: fixed;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      // @extend .maxWidth;
      background: $redBlueGradient;
      background-repeat: no-repeat;
      background-attachment: fixed;
      z-index: -1;
      // @media #{$max}{
      //     left: calc(( 100vw - #{$maxWidth})/2)
      // }
  }

	.streaming{
		padding-top: $vertical-padding;
		padding-bottom: $vertical-padding-large;
		@include pl(1, 2);
		@include pr(1, 2);
      min-height: calc(100vh - #{$nav-height});
      h2{
         margin-bottom: 6vh;
         margin-top: 0px;
      }
      .streaming-channels{
         text-align: center;
         margin-bottom: $vertical-padding;
         .logos{
            @include flex(flex, row, center ,center);
            flex-wrap: wrap;
            
            a{
               width: 33.33%;
               @include flex(flex, column, center ,center);
               margin-bottom:$vertical-padding;
               img{
                  max-width: 80%;
                  max-height: 60px;
                  @include transition(transform 350ms 0ms ease);
                  &:hover{
                     @include scale(1.05,1.05);
                  }
               }
            }
         }
      }
   }
   @media #{$tablet} {
		.streaming {
         @include pl(1, 0);
         @include pr(1, 0);
         .streaming-channels{
            .logos{
               a{width: 33%;}
            }
         }
		}
	}
	@media #{$phone} {
		.streaming {
         min-height: calc(100vh - #{$nav-height-mobile});
         @include pl(1, 1);
         @include pr(1, 1);
         .streaming-channels{
            .logos{
               a{
                  width: 50%;
                  img{
                     max-width: 90%;
                     max-height: 30px;
                     &:hover{
                        @include scale(1,1);
                     }
                  }
               }
            }
         }
		}
	}
}


// ---------------------------------------------
// General Heading and Paragraph Classes
// ---------------------------------------------

.heading-1, h1 {
    font-family: $barlow;
    font-weight: 700;
    font-size: $h1-FS;
    @media #{$tablet-portrait} {
        font-size: $h1-FS * .7;
    }
    @media #{$phone} {
        font-size: $h1-FS * .55;
    }
}

.heading-2, h2 {
    font-family: $barlow;
    font-weight: 700;
    font-size: $h2-FS;
    line-height: $h2-LH;

    @media #{$phone}{
        font-size: $h2-FS - 10;
        line-height: $h2-LH - .1;
    }
}

.heading-3, h3 {
    font-family: $barlow;
    font-weight: 700;
    font-size: $h3-FS;
    line-height: $h3-LH;

    @media #{$phone} {
        font-size: $h3-FS - 8;
    }
}

.heading-4, h4 {
    font-family: $barlow;
    font-weight: 700;
    font-size: $h4-FS;
    @media #{$tablet-portrait} {
        font-size: $h4-FS * .8;
    }
    @media #{$phone} {
        font-size: $h4-FS * .9;
    }
}

.heading-5, h5 {
    font-family: $barlow;
    font-weight: 700;
    font-size: $h5-FS;
    line-height: $h5-LH;

}
// regular paragraph text
.text-r, p {
    font-family:$barlow-cond;
    font-weight: 400;
    font-size: $pr-FS;
    line-height: $p-LH;
    @media #{$phone} {
        font-size:$p-FS;
    }
}

// medium p text. font size 22px
.text-m {
    font-size: $pm-FS;
    line-height: 1.6;
    @media #{$tablet-portrait} {
        font-size:$pr-FS;
    }
    @media #{$phone} {
        font-size:$p-FS;
    }

}

.nav-li {
    color: $darkBlue;
    font-size: $ps-FS + 1;
    font-family: $barlow;
    font-weight: 500;

    &:hover {
        color: $blueGradient;
    }
}


// larger paragraph text
.text-l, .p-l {
    font-size: $pl-FS;

    @media #{$phone} {
        font-size:22px;
    }
}

p a{
    color: $red;
    &:hover{
        color: $darkBlue;
    }
}
.button, button {
    font-family:$barlow-cond;
    font-weight: 500;
    font-size: $p-FS;
    @media #{$phone}{
        font-size: $p-FS - 1;
    }
}

.meta-title {
    font-family: $barlow-cond;
    font-weight: 400;
    text-transform: uppercase;
    font-size: $small-FS;
    letter-spacing: 1px; // readability !!!
    @media #{$phone} {
        font-size:13px;
    }
    
}

.number {
    @extend .heading-1; 
}

.bold {
    font-weight: bold;
}

// ---------------------------------------------
// Color Classes
// ---------------------------------------------

.white {
    color: white;
}

.gray {
    color: $copyGray;
}

.red {
    color: $red;
}


.text-slider-holder {
   position: relative;
   height: 100%;
   padding-bottom: $vertical-padding;

    .text-slider-bg {
        @include backface-visibility(hidden);
        @include transform(translate3d(0,0,0));
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}
.text-slider {
   @include transform(translate3d(0,0,0));
   @include backface-visibility(hidden);
   position: relative;
   height: 100%;
    
   // max-height: $maxHeight;
   padding-top: $vertical-padding;
   padding-bottom: $vertical-padding;
   // margin-bottom: $vertical-padding-large;
   overflow: hidden;
   outline: none;

   .flickity-slider {
   	width: 100%;
    	// height: 100%;
   }
   .flickity-viewport {
   	// @include transition(height, 550ms, 0ms, ease);
   	transition: height 0.2s;
   }

   .slide {
     	text-align: center;
     	width: 100%;
     	@include box-sizing();
     	@include flex(flex,column, center, center);
     	padding-bottom: $vertical-padding;
     	padding-top: $vertical-padding;
     	.text {
      	@include pl(1,1);
     		@include pr(1,1);
      	margin: 0 auto;

         h3 {
            font-size: 65px;
            margin: 25px auto;

            @media #{$full} {
               font-size: 60px;
            }
            @media #{$tablet} {
               font-size: 50px;
            }
            @media #{$phone} {
               font-size: 30px;
            }
         }
  	   }
   }
	.flickity-page-dots {
		left: 50%;
		transform: translateX(-50%);
		padding-left:0;
	} 
	@media #{$phone} {
     	.flickity-viewport {
         padding:  0px;
	   }   
   }
}
.logo-grid {
    background-color: $lightGray;
    padding-top: $vertical-padding-large;
    padding-bottom: $vertical-padding-large;
    @include pl(1,1);
    @include pr(1,1);

    h5, p {
        margin: 0px auto;
        text-align: center;
    }

    p {
        margin-top: 15px;
        margin-bottom: $vertical-padding;
        @include innerWidth(6,5,.83);
    }

    &-logos {
        @include flex(flex,row,center, center);
        width: 100%;
        flex-wrap: wrap;

        .image {
            display: block;
            flex: 0 25%;
            

            img {
                display: block;
                margin: $vertical-padding auto;
                width: 130px;
                height: auto;
            }
            @media #{$tablet} {
                flex: 0 33%;
                text-align: center;
                img{ width: 50%;}
            }

            @media #{$phone} {
                flex: 0 50%;
                text-align: center;
                img{ width: 50%;}
            }
        }
    }
    // Phone Logo Grid
    @media #{$phone} {
        padding-top: $vertical-padding-small;
        padding-bottom: $vertical-padding-small;
        p{   width: 100%; }
    }
}
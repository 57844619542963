// ---------------------------------------------
//  press list and search result list
// ---------------------------------------------
.press-list {
   box-sizing: border-box;
   background: $darkBlue;
   padding-top: $vertical-padding;
   padding-bottom: $vertical-padding;
   header {
      @include pr(1, 1);
      @include pl(1, 1);
      padding-bottom: $vertical-padding;
   }
   li {
      width: 100%;
      margin: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      @include pr(1, 1);
      @include pl(1, 1);
      @include hover-arrow(".link", 18px, 6px, 40%, white);
      position: relative;
      box-sizing: border-box;
      a {
         @include flex(flex, row, flex-end, space-between);
         flex-wrap: wrap;
      }

      &:before {
         // BOTTOM BORDER
         content: "";
         position: absolute;
         bottom: 0px;
         height: 1px;
         left: 0;
         @include ml(1, 1);
         background-color: rgba(255, 255, 255, 0.3);
         width: calc(100% - #{$gutter * 2} - #{$column * 2});
      }

      &:hover {
         background-color: rgba(255, 255, 255, 0.1);
         .link {
            @include transition(opacity 250ms);
            opacity: 1;
         }
      }
      .link {
         font-size: 16px;
         font-family: $barlow;
         margin-right: 0;
         position: relative;
         right: 30px;
         opacity: 0;
      }

      .text {
         padding-right: 30px;
      }
   }
   .button {
      display: inline-block;
      margin-top: $vertical-padding;
   }
   // Phone Press list
   @media #{$phone} {
      header {
         @include pr(1, 0);
         @include pl(1, 0);
         padding-bottom: $vertical-padding-small;
      }
      li {
         @include pr(1, 0);
         @include pl(1, 0);
         &:before {
            // BOTTOM BORDER
            @include ml(1, 0);
         }
         > .link {
            display: none;
         }
      }
   }

   @media #{$mobile} {
      > .link {
         display: none;
      }
   }
}

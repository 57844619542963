
.modal-wrap-leadership {
    background: $redBlueGradient;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    overflow: scroll;
    opacity: 1;

    .contents {
        overflow: scroll;
    }

    h1 {
        margin: 0;
    }

    .image {
        height: 30vw;
        @include full-bk(center 20%, cover);
        position: relative;
        background: $blueGradient;
        .text {
            position: absolute;
            bottom: $vertical-padding;
            @include iml(1,1, .673);
        }
    }

    .summary {
        @include ipl(1,1,.673);
        @include ipr(1,1,.673);
        padding-top: $vertical-padding;
        padding-bottom: $vertical-padding;
        background: white;
        p {
            margin-bottom: 40px;
            @extend .text-m;
        }
    }

    @media #{$phone}{
        h1 {
            font-size: $h3-FS;
        }

        .image {
            height: 50vw;
            .text {
                position: absolute;
                bottom: $vertical-padding;
                @include iml(1,1, .92);
                bottom: 10px;
            }
        }
        .summary{
            @include ipl(1,1,.92);
            @include ipr(1,1,.92);
            padding-top: $vertical-padding-small;
            padding-bottom: $vertical-padding-small;
        }
    }
    
}
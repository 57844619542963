// SEARCH GLOBAL
.search-bar {
    width: 100%;
    height: 100%;
    background-color: white;

    form {
        height: 100%;
        width: 100%;
        @include box-sizing();

        input {
            height: 100%;
            outline: none;
            border:none;

            @media #{$phone} {
               font-size: 22px;
            }
        }

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $borderGray;
            @media #{$phone} {
               font-size: 22px;
            }
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: $borderGray;
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: $borderGray;
        }
        :-moz-placeholder { /* Firefox 18- */
          color: $borderGray;
        }
    }
}


// SEARCH RESULTS PAGE
.search-wrap {
    padding-bottom: $vertical-padding;
}
.search-results{
    &-header {
        @include pl(1,1);
        @include pr(1,1);
        padding-top: $vertical-padding;

        form {
            input {
                width: 100%;
                @extend .heading-1;
                border-bottom: 1px solid $copyGray;
                @media #{$phone} {
                    padding-top: 15px;
                    font-size: 30px;
                }
            }
        }
        @media #{$phone} {
            @include pl(1,0);
            @include pr(1,0);
        }
    }

    .flex-blocks {
        @extend .blocks;
    }

    .no-results {
        @include pl(1,1);
        @include pr(1,1);
        
        @media #{$phone} {
            @include pl(1,0);
            @include pr(1,0);
        }
    }

    .results-large {
        h3 {
            @include pl(1,1);
            @include pr(1,1);
            @media #{$phone} {
                @include pl(1,0);
                @include pr(1,0);
            } 
        }
    }

    .list-results {
        background-color: white;
        
        h3 {
            @include pl(1,1);
            @media #{$phone} {
                @include pl(1,0);
                @include pr(1,0);
            }
        }
        @extend .press-list;
        a {color: $copy;}
        li {
            @include hover-arrow('.link', 18px, 6px, 40%, $copy);
            &:before {
                background-color: $borderGray;
            }
            &:hover {
                &:before {
                    height: 2px;
                }
            }
        }
    }
}
.header-nav {
	@include transition(all 450ms 0ms $easeInQuad);
	@include flex(flex, row, center, space-between);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: $nav-height;
	@include pl(1, 1);
	@include pr(1, 1);
	@include box-sizing();
	// max-width: $maxWidth;
	// @media #{$max}{
	// 	border-right: 1px solid darken($bgGray, 3%);
	// 	border-left: 1px solid darken($bgGray, 3%);
	// 	left: calc(( 100vw - #{$maxWidth})/2)
	// }
	// for mobile menu
	.mm-open & {
		z-index: map-get($z-index, mobileMenu);
		height: 100vh;
		overflow: hidden;
	}
	// Desktop Menu
	nav {
		.nav > li {
			margin: 0 20px;
			display: inline-block;
			position: relative;
			padding: 10px 0px;
			&.active, &:hover {
				> a, > span {
					color: $red;
					font-weight: 500;
				}
			}
			a, span {
				@extend .nav-li;
				cursor: pointer;
			}
			&:hover {
				.sub-menu{
					opacity: 1;
					pointer-events: auto;
				}
			}

			&.search {
				cursor: pointer;
				margin-right: 0px;

				&:hover {
					#search {
						fill: $red;
					}
				}

				svg {
					height: 100%;
				}
			}
			&.mm-btn {
				height: 21px;
				width: 16px;
				margin-right: 0px;
				span {
					width: 16px;
					height: 2px;
					position: absolute;
					top: 50%;
					background-color: $darkBlue;
					&:first-child {
						@include transform(translateY(-6px));
					}
					&:last-child {
						@include transform(translateY(6px));
					}
				}
				.search-open & {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}
	.sub-menu{
		position: absolute;
		z-index: 1000;
		width: 100%;
		left: 0px;
		top: 100%;
		background-color: white;
		border:1px solid $lightGray;
		min-width: 200px;
		padding: 20px;
		box-sizing: border-box;
		@include transform(translateX(-30px));
		opacity: 0;
		pointer-events: none;
		li{
			display: block;
			&:last-child{
				margin-bottom: 0px;
			}
			&:hover > a,
			&.active > a {
				color: $red;
				font-weight: 500;
			}
		}
	}
	&.white {
		svg path {
			fill: white;
		}
		nav .nav > li {
			&.mm-btn span {
				background-color: white;
			}
			a {
				color: rgba(white, 0.8);
				&:hover{
					color:white;
				}
			}
			&.active {
				a {
					// color: $red; // THIS LOOKS WEIRD ON CONTACT
					color: rgba(white, 1);
					font-weight: 700;
				}
			}
			.sub-menu{
				li a{
					color: $darkBlue;
					&:hover{
						color:$red;
					}
				}
			}
		}
	}

	.logo {
		svg {
			height: 60px;
		}
	}

	.search-bar {
		opacity: 0;
		@include transform(translateY(-$nav-height));
		position: absolute;
		top: 0;
		left: 0;
		@include transition(all 250ms);

		&.open {
			opacity: 1;
			@include transform(translateY(0));
		}

		form {
			@include pl(1, 1);
			input {
				width: 85%;
				@extend .heading-3;
			}
			.close-button {
				position: absolute;
				right: 0;
				@include pr(1, 1);
				top: 50%;
				transform: translateY(-50%);
				path {
					fill: $gray;
					opacity: 1;
				}
			}
		}
	}
	@media #{$tablet-portrait} {
		@include pl(1, 0);
		@include pr(1, 0);
		.logo {
			svg {
				height: 40px;
			}
		}
		nav {
			.nav > li {
				margin: 0px 10px;
			}
		}
	}
	
	@media #{$phone} {
		@include pl(1, 0);
		@include pr(1, 0);
		height: $nav-height-mobile;
		.logo {
			svg {
				height: 40px;
			}
		}
		nav:not(.mobile) {
			li:not(.search):not(.mm-btn) {
				display: none;
			}
		}
		nav .nav > li{
			padding:  0px;
		}
		.sub-menu{
			position: relative;
			z-index: 1000;
			padding-top: 10px;
			width: unset;
			top:  unset;
			left: unset;
			background-color: transparent;
			border:0px;
			min-width: unset;
		// padding: unset;
		// box-sizing: border-box;
			@include transform(translateX(0px));
			opacity: 1;
			pointer-events: auto;
			height: unset;
			li{
				margin-bottom: 10px;
				a{font-size: 20px !important;}
			}
		}
		.search-bar {
			form {
				@include pl(1, 0);
				.close-button {
					height: 40px;
					@include pr(0, 1);
				}
			}
		}
	}
}

.mobile-menu {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $redBlueGradient;
	z-index: map-get($z-index, hidden);
	pointer-events: none;
	@include transform(translateY(-100%));
	@include transition(transform 450ms 0ms $easeInQuad, z-index 0ms 450ms);
	.close-menu {
		position: absolute;
		top: $gutter;
		right: $gutter;
	}
	nav.mobile {
		ul:not(.sub-menu) {
			height: 100vh;
			width: 100%;
			@include flex(flex, column, center, center);
			>li {
				width: 100%;
				text-align: center;
				margin-bottom: 15px;
				a, span {
					@extend .heading-3;
					color: white;
				}
			}
		}
	}
	.mm-open & {
		z-index: map-get($z-index, mobileMenu);
		pointer-events: auto;
		@include transform(translateY(0%));
		@include transition(transform 450ms 0ms $easeInQuad, z-index 0ms 0ms);
	}
	@media only screen and (min-device-width: 480px) and (max-device-width: 740px) and (orientation: landscape), only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape), (min-device-width : 375px) and (max-device-width : 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 3){
		nav.mobile {
			ul:not(.sub-menu) {
				height: auto;
				width: 90%;
				margin-left: 10%;
				margin-top: 5%;
				@include flex(flex, row, flex-start, flex-start);
				flex-wrap: wrap;
				>li {
					width: 40%;
					text-align: left;
				}
			}
			ul.sub-menu {
				padding-left: 0px;
			}
		}
	}
}

.film-tv-index {
	.header-nav {
		border-bottom: 2px solid $lightGray;
	}
}

.press {
	.header-nav {
		background-color: $darkBlue;
	}
}

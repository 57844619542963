
//===============================================================/
//  responsive sizes (mobile inclues tablet + phone)
//===============================================================/

$mega:      		'screen and (min-width: 1700px)';
$max:      		   'screen and (min-width: 1400px) ';
$full:      		'screen and (min-width: 1100px) ';
$tablet-landscape:  'screen and (max-width: 1099px) and (min-width: 940px)';
$tablet-portrait:   'screen and (max-width: 939px) and (min-width: 767px)';
$tablet:		    'screen and (max-width: 1099px) and (min-width: 767px)';
$phone:     		'screen and (max-width: 766px)';
$mobile:    		'screen and (max-width: 939px)'; // includes tablet and phone
$retina:			'only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 1.5)';



//===============================================================/
//  Master Grid Variables
//===============================================================/

$columnCount:		12;
$gutter:		    2%;
$column:		    (100% - ($gutter * ($columnCount + 1) ) ) /$columnCount;



//===============================================================/
//  Typography
//===============================================================/

$copy_FS:		    16px;

$menu_FS:			14px;
$btn_FS:			14px;

$h1_FS:			    38px;
$h2_FS:			    32px;
$h3_FS:			    24px;
$h4_FS:			    18px;
$h5_FS:			    16px;
$h6_FS:			    12px;


// ---------------------------------------------
// Fonts
// ---------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,700|Barlow:400,500,700');

// ---------------------------------------------
// Fonts
// ---------------------------------------------
$barlow : "Barlow", sans-serif;
$barlow-cond: 'Barlow Semi Condensed', sans-serif;


// ---------------------------------------------
// Spacing
// ---------------------------------------------

$vertical-padding-xlarge:       32vh;
$vertical-padding-large:        16vh;
$vertical-padding:              8vh;
$vertical-padding-small:        4vh;
$button-spacing:                1.5em;

// ---------------------------------------------
// Sizing
// ---------------------------------------------

$nav-height: 	125px;
$nav-height-mobile: 75px;
$maxWidth:1400px;
// ---------------------------------------------
// Typography
// ---------------------------------------------
$small-FS:      14px;
$p-FS:          18px;
$pl-FS:         24px;
$pm-FS:         22px;
$pr-FS:         20px;
$ps-FS:         17px;
$p-LH:          1.7;
$pl-LH:         1.7;
$ps-LH:         1.7;

$h1-FS:         55px;
$h1-LH:         1.45;

$h2-FS:         44px;
$h2-LH:         1.45;

$h3-FS:         36px;
$h3-LH:         1.35;

$h4-FS:         26px;
$h4-LH:         1.65;

$h5-FS:         34px;
$h5-LH:         1.65;


// ---------------------------------------------
// Colors
// ---------------------------------------------
$copy      : #09162D;		
$darkBlue  : #1A2F54;
$red       : #E50C30;
$offWhite  : #F1F5F9;
$gray      : rgba(201,206,214,47);
$lightGray : #F4F6F8;
$copyGray  : #939EAE;
$borderGray: #C9CED6;
$bgGray    : #F8F9FA;
// ---------------------------------------------
// Gradients
// ---------------------------------------------

$readabilityGradient: linear-gradient(180deg, rgba(26,47,84,0) 0%, rgba(5,16,35,0.5) 100%);
$redBlueGradient: linear-gradient(354.93deg, $red 0%, #152849 100%);
$blueGradient: linear-gradient(299.9deg, #1A2F54 0%, #051023 100%);

// ---------------------------------------------
// Animations
// ---------------------------------------------

$outQuad: cubic-bezier(0.165, 0.840, 0.190, 0.950);
$easeInQuad : cubic-bezier(0.895, 0.03, 0.685, 0.22);

// ---------------------------------------------
// screen sizes
// ---------------------------------------------

$desktop:  'screen and (min-width: 767px)';


// ---------------------------------------------
// Z-index
// ---------------------------------------------

$z-index: (
    hidden : -1,
    
    blockText: 10,
    sliderProgress:90,
    filterOverlay: 100,
    modal: 100,
    mobileMenu: 110,
    ticketFilter: 50,
    sliderText :9,
    sliderButtons :15,
    sliderLink: 11
);

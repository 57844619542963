.blocks-grid {
    h3 {
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .flex-blocks {
        @extend .blocks;
    }

    .header {
        padding: $vertical-padding 0 $vertical-padding-small 0;
    }
    .button {
        margin-top: $button-spacing;
    }
    a {
        display: inline-block;
    }
}
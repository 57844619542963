.homepage-slider {
   position: relative;
   height: calc(100vw * 0.5625);
   width: 100%;
   overflow: hidden;
   outline: none;
   // max-height: 860px;
   opacity: 0;
   @include transition(opacity 350ms 0ms ease);
   -webkit-transform: translate3d(0, 0, 0);

   @extend .maxWidth;

   &.flickity-enabled {
      opacity: 1;
   }
   .flickity-slider,
   .flickity-viewport {
      height: 100% !important;
      width: 100%;
      background-color: $darkBlue;
   }
   .flickity-slider {
      will-change: transform;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      //  @include transition(transform 300ms 50ms );
   }
   .slide {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      @include box-sizing();
      position: relative;
      @include hover-arrow(
         h1,
         29px,
         8px,
         25px,
         $color: white
      ); //$el, $length, $arrowSize, $bottom ,$color

      .full-link {
         position: absolute;
         width: 100%;
         height: 100%;
         z-index: map-get($z-index, sliderLink);
      }

      h1 {
         position: relative;
         display: inline;
      }

      // &.move-left {
      //     @include transform(translateX(-20px));
      //     .info{
      //         @include transform(translateX(20px));
      //     }
      // }
      // &.move-right {
      //     @include transform(translateX(20px));
      //     .info {
      //         @include transform(translateX(-20px));
      //     }
      // }

      // &.scale {
      //     @include transform(scale(1.05));
      // }

      // &.scale-down {
      //    .img-holder {
      //         box-shadow: 0px 2px 39px 0px rgba(0,0,0,.5);
      //         @include transform(scale(0.8));

      //         .info {
      //             opacity: 0;
      //             @include transition(opacity 0ms 0ms $outQuad);
      //         }
      //     }
      //     &:after {
      //         @include transform(translateY(50px));
      //     }
      // }

      // &.transition {
      //     @include transition(transform 550ms 0ms $outQuad);
      // }

      .img-holder {
         // @include transition(transform 450ms);
         position: relative;
         height: 100%;
         // @include full-bk(center top, cover);
         img{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
         }
      }

      .info {
         @include flex(flex, row, center, space-between);
         position: absolute;
         bottom: 45px;
         width: 100%;
         @include box-sizing();

         // @include transition(transform 550ms 0ms $outQuad, opacity 250ms 250ms);

         flex-wrap: wrap;

         .buttons {
            @include flex(flex, row, center, space-between);
            align-self: flex-end;
            flex-wrap: wrap;
            z-index: map-get($z-index, sliderButtons);
            position: relative;
            button:nth-child(1) {
               margin-right: $button-spacing;
            }
            @media #{$phone} {
               width: 100%;
            }
         }

         .text {
            @include pr(2, 1);
            flex: 1;
            z-index: map-get($z-index, sliderText);
            p {
               color: $offWhite;
            }
         }
      }
      @extend .gradient;
      &:after {
         @include transition(transform 450ms 250ms);
      }
   }

   .info,
   .flickity-page-dots {
      @include pl(1, 1);
      @include pr(1, 1);
   }

   .flickity-prev-next-button {
      position: absolute;
      // top: 50%;
      // top:auto;
      // bottom: 20px;

      // height: 15px;
      padding: 0;
      // opacity: 1;
      border: none;
      // background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("/images/arrow.svg");
      background-size: 45px;
      opacity: 0.8;
      @include transition(opacity 250ms $outQuad);
      height: 100%;
      top: 0%;
      width: 5%;

      &.disabled {
         display: none;
      }
      &:hover {
         opacity: 1;
         //    background-size:   45px;
      }

      svg {
         display: none;
      }

      &.previous {
         left: 0px;
         padding-left: $gutter;
         right: unset;
         @include transform(rotate(180deg));
      }

      &.next {
         left: unset;
         padding-right: $gutter;
         right: 0px;
      }
   }

   @media #{$max} {
      height: calc(#{$maxWidth} * 0.5625);
   }
   @media #{$phone} {
      .slide {
         .info {
            bottom: 15px;
         }
      }
      .info,
      .flickity-page-dots {
         @include pl(1, 0);
         @include pr(1, 0);
      }

      h1 {
         font-size: 24px;
      }
      .flickity-prev-next-button {
         width: 15%;
         background-size: 30px;
         &.previous {
            padding-left: 0;
            @include transform(rotate(180deg));
         }

         &.next {
            padding-right: 0;
         }
      }

      .flickity-page-dots {
         margin-bottom: 5px;
         li {
            height: 10px;
            width: 25px;
            margin-right: 10px;
            &:before {
               width: 25px;
               //@include transition(transform 7000ms 0ms);
            }
         }
      }
      // .flickity-prev-next-button {
      //     display:none;
      // }
      // height: calc(100vh - #{$nav-height-mobile});

      // .info{
      //     margin-bottom: $vertical-padding-small;
      // }
      // .text{
      //     width: 100%;
      //     margin-bottom: $vertical-padding-small/2;
      // }
   }
}

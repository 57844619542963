// hover arrow mixin
// pass in $el because the hover element is either the text or the containing block. 
 //$el, $length, $arrowSize, $bottom ,$color
@mixin hover-arrow($el, $length, $arrowSize, $bottom:50% ,$color:white) {
    position: relative;
    #{$el}:after {
        content: '';
        background-color: $color;
        position: absolute;
        @include transition(all 250ms 0ms ease);
        @include transform-origin(50%, 0%);
        margin-left: 8px;
        opacity: 0;
        width: 0px;
        height: 2px;
        left: 100%;
        bottom: $bottom;
    }

    #{$el}:before {
        content: '';
        position: absolute;
        @include transition(all 250ms 0ms ease);
        @include transform(translate3D(0, 50%, 0) rotate(135deg) scale(.1,.1) );
        background-color: transparent;
        opacity: 0;
        bottom: $bottom;
        left: calc(100% + #{$length});
        width: $arrowSize;
        height: $arrowSize;
    }
    &:hover {
        #{$el}:after {
            opacity: 1;
            width: $length;
            @include transform(translate3D(0, 50%, 0));
            @include transition(all 250ms 0ms ease);
            @include transform-origin(50%, 50%);
        }
        #{$el}:before {
            opacity: 1;
            border-left: 2px solid $color;
            border-top: 2px solid $color;
            @include transform(translate3D(0, 50%, 0) rotate(135deg) scale(1,1) );
            
            @include transition(all 250ms 50ms ease);
        }
    }
}


@mixin down-arrow($color, $width, $arrowHead, $left:calc(100% + #{$width/2})) {
    cursor:pointer;
    position: relative;
    &:after {
        content: '';
        background-color: $color;
        position: absolute;
        @include transform(translate3D(0, 50%, 0) rotate(90deg));
        @include transform-origin(50%, 0%);
        margin-left: 6px;
        opacity: 1;
        width: $width;
        height: 2px;
        left: 100%;
        bottom: 10px;
    }

    &:before {
        content: '';
        position: absolute;
        @include transition(all 250ms 0ms ease);
        @include transform(translate3D(0, 50%, 0) rotate(225deg) scale(1,1) );
        background-color: transparent;
        border-left: 2px solid $color;
        border-top: 2px solid $color;
        opacity: 1;
        bottom: 9px;
        left: $left;
        width: $arrowHead;
        height: $arrowHead;
    } 
}


.meta-number {
    width: 34%;
    @include flex(flex,row,flex-start,center);
    flex-wrap: wrap;

    .item-wrap {
       flex: 0 50%;
       margin-bottom: $vertical-padding-small;
    }

    .number {
        font-size: $h2-FS;
    }

    @media #{$mobile} {
        width: 100%;
    }
}
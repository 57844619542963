
.film-tv-index-grid{
	@include transition(all 550ms 0ms $easeInQuad); 
	.loading &{
		@include transition(all 0ms 0ms $easeInQuad); 
		opacity: 0;
	}
}

// ------------------------------
// FILTER OVERLAY THAT IS ACTIVATED WHEN THE FILTER BAR IS CLICKED
.filter-overlay{
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	background: $redBlueGradient;
	text-align: center;
	padding-top: $nav-height;
	padding-bottom: $vertical-padding-large;
	pointer-events: none;
	// starting position
	z-index: map-get($z-index, hidden);
	opacity: 0;
	@include box-sizing;
	@include transform(translateY(-100vh));
	// transition
	@include transition( opacity 0ms 550ms , z-index 0ms 550ms , transform 550ms 0ms $easeInQuad);

	// max-width: $maxWidth;
	// @media #{$max}{
	// 	left: calc(( 100vw - #{$maxWidth})/2)
	// }
	.loading &{
		@include transition( opacity 0ms  , z-index 0ms  , transform 550ms 0ms $easeInQuad);
	}
	.filter-title{
		@extend .heading-4;
		font-weight: 300;
		color: $copyGray;
		
	}
	ul.options{
		li {
			margin-bottom: 0px;
		}
		h2{
			display: inline-block;
			position: relative;
			margin-bottom: 0px;
			margin-top: 15px;
			a{
				@include hover-arrow('&', 20px, 8px, 40%); //$el, $length, $arrowSize, $bottom ,$color
				color: $gray;
				&:hover {
					color: white;
				}
			}
		}
	}
	.close {
		position: absolute;
		right: $gutter;
		top: $gutter;
		height: 50px;
		width: 50px;
		cursor: pointer;
		svg {
			height: 100%;
			width: 100%;
		}
	}	
	&.filter-active{
		@include transition( opacity 0ms 0ms , z-index 0ms 0ms , transform 550ms 0ms $easeInQuad);
		pointer-events: auto;
		opacity: 1;
		z-index: map-get($z-index, filterOverlay);
		overflow: scroll;
		@include transform(translateY(0vh));	
	}
	@media #{$phone}{}
}

// ------------------------------
// FILTER BAR AT TOP OF INDEX PAGES
.filter-bar{
	@include hover-arrow(span, 14px, 6px, 45%, $red); //$el, $length, $arrowSize, $bottom ,$color
	cursor: pointer;
	@include transition(transform 550ms 0ms $easeInQuad); 
	// FIXED ------------
	&.fixed{
		
		
		.text{
			position: fixed;
			top:0px;
			padding:  $vertical-padding-small/4 0 ;
			z-index: map-get($z-index, filterOverlay);
			max-width: $maxWidth;
			h1.filter{
				font-size: $h3-FS - 2;
			}
		}
	}
	// //////////
	.text{
		@extend .meta-title;
		padding:  $vertical-padding-small 0 ;
		color: $red;	
		text-align: center;
		width: 100%;
		background-color: white;
		@include transition(all 350ms 0ms $outQuad);
		> span{
			display: inline-block;
			position: relative;
		}
	}
	h1.filter{
		text-transform: capitalize;
		color: $copy;
		margin-bottom: 0px;
		@include transition(all 350ms 0ms $outQuad);
	}
	&:hover{
		// background:$redBlueGradient;
		// background-color: $lightGray;
		.text{
			background-color: $lightGray;
		}
	}
}

.button, button {
    background: transparent;
    padding: $button-spacing/2 $button-spacing;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
    @include transition(all 150ms 0ms ease-out);

    &-primary {
        background: white;
        color: $darkBlue;
        &:hover {
            background-color: $offWhite;
        }
    }

    &-secondary-white{
        color: white;
        border: 2px solid white;

        &:hover {
            background-color: white;
            color: $darkBlue;
        }
    }

    &-secondary-blue{
        color: $darkBlue;
        border: 2px solid $darkBlue;

        &:hover {
            background-color: $darkBlue;
            color: white;
        }
    }

    &-secondary-red {
        color: $red;
        border: 2px solid $red;

        &:hover {
            background-color: $red;
            color: white;
        }
    }

    &-play { // includes a play button svg inside the button
        @include flex(flex,row,center,space-between);
        padding: 0;
        svg {
            padding-left: $button-spacing/2;
            height: 30px;
            width: 30px;

            path {
                @include transition(all 350ms $outQuad);
                transform-box: fill-box;
                transform-origin: center center;
                
                stroke: $darkBlue;
                fill: $darkBlue !important; //whyyy
            }
        }
        span {
            padding: $button-spacing/2 $button-spacing $button-spacing/2 $button-spacing/2;
        }

        &:hover {
            .circle-group {
                @include transition(all 550ms $outQuad);
                transform-origin: center center;
                transform: scale(0.9);
            }
            .triangle {
                @include transform(scale(1.1));
               
            } 
        }
    }
    @media #{$phone}{
        padding: $button-spacing/2 $button-spacing/1.5;
        &-play { // includes a play button svg inside the button
            padding: 0px;
            margin-bottom: 0px;
            padding-left: 40px;
            position: relative;
            svg {
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            span {
                // padding: $button-spacing/3 $button-spacing/1.5 $button-spacing/3 $button-spacing/3;
            }

            &:hover {
               
            }
        }
    }
}
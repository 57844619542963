
// import globals
@import 'vanilla/vanilla';
@import 'variables';
@import 'mixins';
@import 'typography';
@import 'buttons';
@import 'global';
@import 'footer';
@import 'modal';
@import 'nav';
@import 'press-list';


// import fields
@import './fields/home-page-slider.scss';
@import './fields/home-page-blocks-grid.scss';
@import './fields/where-to-watch.scss';
@import './fields/gradient-title-text.scss';
@import './fields/logo-grid.scss';
@import './fields/summary.scss';
@import './fields/meta-number.scss';
@import './fields/team-and-leadership.scss';
@import './fields/image-page-header.scss';
@import './fields/meta-info.scss';
@import './fields/text-slider.scss';
@import './fields/ticket-list.scss';
@import './fields/press-kit.scss';

@import 'page-streaming';

//pages
@import 'film-tv-index';
@import 'contact';
@import 'leadership';
@import 'search';


.where-to-watch {
    background-color: $darkBlue;
    @include flex(flex,row,center,space-between);
    @include box-sizing();
    @include pl(1,2);
    @include pr(1,2);
    flex-wrap: wrap;
    max-height: 600px;
    overflow:hidden;

    @media #{$phone} {
        flex-direction: column-reverse;
        padding-bottom: $vertical-padding;
    }

    .text {
        width: 40%;

        h3 {
            margin-top: 0;
            margin-bottom: 15px;
        }

        @media  #{$phone} {
            width: 100%;

        }
    }

    .logo {
        position: relative;
        top: 0;
        padding-top: 13vw;
        padding-bottom: 13vw;
        width: 50%;
        @include flex(flex,column,center,space-between);

        .graphic {
            height: 100%;
            position: absolute;
            top: 0;
            pointer-events: none;

            svg {
                height: 98%;
                margin-top:1%;
            }            
        }
     
        .button {
            margin-top: $button-spacing;
        }
        img{
            width: 22vw;
        }

        @media  #{$phone} {
            width: 60%;
            .graphic{
                left: 50%;
            }
            img{
                max-width: 100%;
            }
            
        }
    }
}
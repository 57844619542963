.ticket-list {
    @extend .press-list;
    background: $bgGray;
    @include pl(1,1);
    @include pr(1,1);
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding;
    width:100%;
    @include box-sizing();

    $width: 18px;
    $arrowHead:7px;
    position: relative;
    overflow: hidden;

    .graphic {
        position: absolute;
        left:0;
        top:0;
    }
    // location filter list + title
    header {
       padding:0;
       width: 100%;
       padding-bottom: $vertical-padding;
       @include flex(flex,row, flex-start, space-between);
       flex-wrap: wrap;
       h1 {
        padding-right: 20px;
        flex:1;
       }
       .filter {
            position: relative;
            text-align: right;
            &.open {
                ul {
                    opacity: 1;
                }
                .filter-dropdown {
                    &:after {
                        opacity:1;
                    }
                }
            }

            h4 {
                text-transform: capitalize;
                margin-top: 10px;
                @extend .p-l;
                position: relative;
                right: 20px;
                @include down-arrow($red, $width, $arrowHead);
            }
        }
        .filter-dropdown {
            min-width: 260px;
            position: absolute;
            right:0;
            z-index: map-get($z-index, ticketFilter);

            &:after {
                @include shadow(0px, 4px, 20px, -2px, rgba(0,0,0,.3));
                content: "";
                height: 100%;
                width:100%;
                position: absolute;
                top: -15px;
                left: 15px;
                padding-bottom: 15px;
                background: white;
                z-index: map-get($z-index, hidden);
                opacity: 0;
            }
        }
        ul {
            overflow-y: scroll; 
            opacity: 0;
            width: 100%;
            height: 0px;
            max-height: 180px;
            @include pr(1,1);
            @include transition(height 350ms $outQuad);

            li {
                text-transform: capitalize;
                cursor: pointer;
                font-family: $barlow;
                font-weight: 500;
                width: 100%;
                padding: 5px 0;

                &.hoverable {
                    &:hover {
                        color:$red;
                    }
                } 
            }
        }
    }

    // tickets list row titles
    .list-titles {
        width: 100%;
        @include flex(flex,row,flex-start,flex-start);
        p {
            flex:1;
            margin: 0;
            &:nth-child(3),
            &:nth-child(4) {
                flex:.5;
            }
        }
        @media #{$phone}{
            display: none;
        }
    }

    // tickets list
    .tickets-ul li {
        padding: 10px 0;
        @include hover-arrow('.link', 18px, 6px, 40%, $red);
        &:before {
            background-color: $gray;
            margin: 0;
            width: 100%;
        }

        &.closed {
            opacity: 0;
            height:0;
            padding: 0;
        }

        a, > div {
            color: $copy;

            p{
                font-family: $barlow;
                line-height: 1.35;
                &:nth-child(1) {
                    font-weight: 500;
                }
            }
            .fake-link{
                flex:.5;
                font-size: 16px;
                // color: fade-out($copy, .3);
                color:$copyGray;
                right: 0;
                margin: 0px;
                text-align: right;
            }
            .link {
                text-align: right;
                // width: 25%;
                // display: block;
                font-size: 16px;
                opacity: 1;
                right: 0;
                @include transition(transform 250ms $outQuad);
            }
        }
        &.hoverable {
            // .link {display: block;}
            &:hover {
                a {color: $red;}
                &:before {
                    background-color: $red;
                }
                .link {
                    @include transform(translateX(-30px));
                }
            }
        }
        // theater + location
        .text {
            width:100%;
            @include flex(flex,row,flex-start, flex-start);
            flex-wrap: wrap;
            padding: 0px;
            margin-top: 10px;
            p {
                padding-right: 10px;
                flex:1;
                &:nth-child(3),
                &:nth-child(4) {
                    flex:.5;
                }
                @media #{$phone}{
                    flex: 0 0 100% !important;
                }
            }
        }
    }
    @media #{$mobile}{
        @include pl(1,0);
        @include pr(1,0);
    }

    @media #{$phone} {

        .filter {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 50px;
            left: 0;
            .filter-dropdown {
                text-align: left;
                padding-left: 25px;
                width: 100%;
                span {
                    display: block;
                }
                h4 {
                    display: inline-block;
                    padding-left: 20px; 
                    &:before {
                        bottom: 5px;
                    }
                }
            }
            ul {
                width:100%;
            }
        }
    }
}
// ---------------------------------------------
// GLOBALS
// ---------------------------------------------

body {
   // tv-Filmfilter open animation
   // Also mobile menu
   &.filter-open,
   &.mm-open {
      height: 100vh;
      overflow: hidden;

      .film-tv-index-grid,
      .filter-bar {
         @include transform(translate3d(0, 100vh, 0));
      }

      #container {
         // @include transition(all 550ms 0ms $easeInQuad);
         height: 100vh;
         overflow: hidden;
      }
   }
   &.mm-open {
      main {
         @include transform(translate3d(0, 100vh, 0));
      }
   }

   &.loading {
      header,
      #content {
         @include opacity(0);
      }
   }
   header,
   #content {
      @include transition(opacity 650ms 50ms ease-in-out);
      @include opacity(1);
   }
   #content {
      overflow: hidden;
   }
   // #container{
   //    @extend .maxWidth;
   //    box-sizing: border-box;
   //     @media #{$max}{
   //         border-right: 1px solid darken($bgGray, 3%);
   //         border-left: 1px solid darken($bgGray, 3%);
   //     }
   // }
}

.maxWidth {
   max-width: $maxWidth;
   margin-left: auto;
   margin-right: auto;
}
main {
   margin-top: $nav-height;
   @include transition(transform 450ms 0ms $easeInQuad);
   @media #{$phone} {
      margin-top: $nav-height-mobile;
   }
}

html,
body {
   height: 100%;

   &.modal-open {
      height: 100vh;
      overflow: hidden;
   }
}

// ---------------------------------------------
// Movie and TV blocks - extend
// ---------------------------------------------
.blocks {
   @include flex(flex, row, flex-start, flex-start);
   width: 100%;
   flex-wrap: wrap;

   a {
      display: block;
      height: calc(50vw * 0.667);
      flex: 0 50%;
      background: $redBlueGradient;
      @include hover-arrow(
         h4,
         20px,
         8px,
         20%
      ); //$el, $length, $arrowSize, $bottom ,$color
      // flex grid px fix
      margin-bottom: -1px;

      // special Hover
      @media #{$desktop} {
         &:hover {
            .image {
               &:before {
                  opacity: 0.4;
               }
            }
         }
      } // Media Query
   }

   .gradient-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $redBlueGradient;
   }

   .image {
      @include transition(all 650ms 0ms cubic-bezier(0.215, 0.61, 0.355, 1));
      position: relative;
      @include full-bk(top center);
      height: 100%;
      @extend .gradient;
      // @extend .hover-gradient;
      &:before {
         content: "";
         opacity: 0;
         position: absolute;
         bottom: 0px;
         width: 100%;
         height: 100%;
         background: $redBlueGradient;
         // mix-blend-mode: color;
         // background-blend-mode: color;
         @include transition(all 300ms ease-in-out);
      }
   }

   .text {
      position: absolute;
      bottom: $gutter * 2;
      @include iml(0, 1, 0.5);
      z-index: map-get($z-index, blockText);

      .meta-title {
         display: inline-block;
         margin-bottom: 10px;
      }

      h4 {
         margin: 0;
      }
   }

   // @media #{$max}{
   //     a{
   //         height: calc((#{$maxWidth}/2) * .667);
   //     }
   // }
   @media #{$phone} {
      a {
         flex: 0 100%;
         height: calc(100vw * 0.667);
      }
      .text {
         bottom: $vertical-padding-small;
         @include iml(1, 0, 1);
         .meta-title {
            margin-bottom: 3px;
         }
      }
   }
}
// ---------------------------------------------
// Lazy Load Image Effect
// ---------------------------------------------
.lazy-fade-in {
   @include opacity(0);
   @include transition(opacity 350ms 0ms ease);
   &.lazyloaded {
      @include opacity(1);
   }
}

// ---------------------------------------------
// Gradient behind text to increase readability - extend
// ---------------------------------------------
.gradient {
   &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: $vertical-padding-xlarge;
      background: $readabilityGradient;
      left: 0px;
   }
}
// ---------------------------------------------
// Hover Gradient - extend
// ---------------------------------------------
.hover-gradient {
   &:before {
      opacity: 0;
      content: "";
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 100%;
      background: $redBlueGradient;
      @include transition(opacity 350ms $outQuad);
   }

   &:hover {
      &:before {
         opacity: 0.8;
      }
   }
}

// ---------------------------------------------
// Summary + Meta Blocks
// ---------------------------------------------
.summary-meta {
   @include flex(flex, row, flex-start, space-between);
   flex-wrap: wrap;
   margin-bottom: $vertical-padding;
   @include pl(1, 1);
   @include pr(1, 1);

   .summary-text {
      @include pr(1, 1);
   }

   // if text is on the right and meta on the left like on film tv pages.
   &.reversed {
      flex-direction: row-reverse;
      .summary-text {
         @include pl(1, 1);
         padding-right: 0px;
         @media #{$phone} {
            padding: 0;
         }
      }
   }

   @media #{$phone} {
      @include pl(1, 0);
      @include pr(1, 0);
      margin-bottom: $vertical-padding-small;
      .summary-text {
         @include pr(0, 0);
         @include pl(0, 0);
      }
      &.reversed {
         .summary-text {
            @include pr(0, 0);
            @include pl(0, 0);
         }
      }
   }
   @media #{$tablet} {
      .summary-text {
         @include pr(0, 0);
         @include pl(0, 0);
      }
      &.reversed {
         .summary-text {
            @include pr(0, 0);
            @include pl(0, 0);
         }
      }
   }
}

// ---------------------------------------------
// Slider controls
// ---------------------------------------------
.flickity-page-dots {
   position: absolute;
   bottom: 0px;
   list-style-type: none;
   @include flex(flex, row, center, center);

   li {
      height: 20px;
      width: 40px;
      margin-right: 15px;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      &:after {
         content: "";
         position: absolute;
         top: 50%;
         @include transform(translateY(-100%));
         height: 3px;
         width: 100%;
         background-color: $gray;

         @include transition(height 200ms 0ms ease-out);
      }

      &:before {
         content: "";
         position: absolute;
         bottom: 50%;
         left: -100%;
         height: 6px;
         width: 40px;
         background-color: white;
         //@include transition(transform 7000ms 0ms);
      }

      &:hover,
      &.hovered {
         &:after {
            height: 6px;
            background-color: white;
         }
      }

      &.is-selected {
         &:after {
            height: 6px;
            background-color: $darkBlue;
         }

         &:before {
            z-index: map-get($z-index, sliderProgress);
            @include transition(transform 4000ms 0ms linear);
            @include transform(translateX(40px));
         }
      }
   }
}
.flickity-prev-next-button {
   position: absolute;
   top: 0;
   left: 0;
   @include width(1, 1);
   height: 100%;
   opacity: 0;

   &.next {
      left: auto;
      right: 0;
   }
}

// ---------------------------------------------
// Form Reset
// ---------------------------------------------

input {
   -webkit-appearance: none;
   outline: none;
   @include box-sizing;
   outline: none;
   border: 0px;
   border-radius: 0px;
   padding: 0px;
   width: 100%;
   color: $darkBlue;
   &[type="submit"] {
      width: auto;
   }
}

// ---------------------------------------------
// Responsive Video
// ---------------------------------------------
.embed-content {
   position: relative;
   overflow: hidden;
   max-width: 100%;
   height: auto;
}

.embed-content iframe,
.embed-content object,
.embed-content embed {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

// ---------------------------------------------
//  SVG buttons
// ---------------------------------------------

.close-button,
.play-button {
   cursor: pointer;
   width: 50px;
   height: 50px;
   path {
      fill: $lightGray;
      opacity: 0.5;
   }
   &:hover {
      path {
         transform-box: fill-box;
         opacity: 1;
         @include transition(all 350ms $outQuad);
         transform-origin: center center;
      }
      .circle-group {
         @include transition(all 550ms $outQuad);
         transform-origin: center center;
         transform: scale(0.9);
      }
   }
}

.close-button {
   &:hover {
      path {
         &.close {
            transform: scale(1.15);
         }
      }
   }
}

.play-button {
   &:hover {
      path {
         &.triangle {
            transform: scale(1.15);
         }
      }
   }
}
// ---------------------------------------------
//  Four Oh Four
// ---------------------------------------------
.four-oh-four {
   background: $redBlueGradient;
   height: auto;
}
.four-oh-four-content {
   height: calc(100vh - #{$nav-height});
   width: 100%;
   @include flex(flex, column, flex-start, center);
   > .content {
      @include pl(1, 1);
      color: white;
      a {
         color: white;
         text-decoration: underline;
      }
   }
}

.video-loader {
   position: fixed;
   height: 100vh;
   width: 100vw;
   top: 0px;
   left: 0px;
   z-index: 0;
   opacity: 0;
   pointer-events: none;

   @include transition(opacity 500ms ease, z-index 0ms 500ms ease);
   .loading & {
      z-index: 9999;
      opacity: 1;
   }
   &.hidden {
      opacity: 0 !important;
   }
   video {
      position: fixed;
      min-width: 100%;
      min-height: 100%;
      left: 50%;
      top: 50%;
      @include transform(translate3d(-50%, -50%, 0));
   }
}

.homepage-introduction {
   position: fixed;
   top: 0;
   left: 0;
   height: 100vh;
   width: 100vw;
   z-index: 1000;
   background-color: white;
   @include flex(flex, column, center, center);
   pointer-events: none;
   svg{
      opacity: 0;
      height: 60px;
      margin-bottom: 60px;
   }
   &.disabled{
      display: none;
      z-index: -1;
      height: 0;
      overflow: hidden;
   }
   h1 {
      max-width: 1000px;
      width: 90%;
      margin: 0px auto;
      text-align: center;
      // color: $copy;
      background: linear-gradient(340.93deg, $red 20%, #152849 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      font-size: 70px;
      margin-bottom: 5vh;
      opacity: 0;
      // animation: fadeIn 500ms ease-in-out;
      // animation-delay: 350ms;
      // &:last-child{
      //    animation-delay: 850ms;
      // }
   }
   @media #{$full} {
      h1 {
         font-size: 65px;
      }
   }
   @media #{$tablet} {
      h1 {
         font-size: 50px;
      }
   }
   @media #{$phone} {
      h1 {
         font-size: 35px;
      }
   }
}

// @keyframes fadeIn {
//    0% {opacity: 0;}
//    100% {opacity: 1;}
//  }
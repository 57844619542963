//===============================================================/
//                   For Development ONLY
//===============================================================/

.gridMe{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    &.hidden{
        display: none;
    }

    div {
        position: absolute;
        height: 100%;
        width: $column;
        position: absolute;
        background-color: rgba(2,238,255,.1);
        border-left:1px solid rgba(2,238,255,1);
        border-right:1px solid rgba(2,238,255,1);
        // @include box-sizing;

        @for $nth from 0 through $columnCount{
           &:nth-child(#{$nth + 1}){
                left: ($column * $nth) + ($gutter * ($nth + 1));
            }
        }
    }
}


footer{
	background: $blueGradient;
	padding: 6vh 0 0;
	.content{
		@include width(10,9);
		@include ml(1,2);
	}

	.top{
		margin-bottom: 20px;
		@include flex(flex, row, flex-start, space-between);

		.column{
			flex: 1 100%;
			&.large {
				flex: 2 100%;
			}
			.address-item{
				text-decoration: none;
				font-weight: 500;
				pre {
					line-height: $ps-LH;
				 	font-size: $p-FS;
				}
			}
			ul{
				li{
					@include hover-arrow(a, 18px, 6px ,43% ,white);
					display: table;
					margin-bottom: 10px;
					a{
						line-height: 18px;
						font-size: $p-FS;
					}
				}
			}

			.header{
				color: white;
				font-family: $barlow-cond;
				font-size: $small-FS;
				font-weight: 300;
				letter-spacing: 1px;
				text-transform: uppercase;
				position: relative;
				margin-bottom: 30px;
				display: block;
				&:after{
					content:'';
					position: absolute;
					left: 0px;
					bottom: -15px;
					width: 30px;
					height: 1px;
					background-color: white;
				}
			}

			form.newsletter-form{
				.input-group{
					width: 100%;
					position: relative;
				}
				input{
					@include border-radius(30px);
					background:transparent;
					outline: none;
					padding: 20px 15px;
					&[type=email]{
						border: 1px solid white;
						font-family: $barlow;
						font-size: $small-FS;
						font-weight: 300;
						padding-left: 20px;
					}
					&[type=submit]{
						position: absolute;
						padding:16px;
						right: 5px;
						top: 5px;
						font-family: $barlow;
						font-size: $small-FS;
						font-weight: 700;
					}
				}
				.ajax-message{
					margin-top: 10px;
					font-family: $barlow;
					font-size: $small-FS;
					font-weight: 300;
					padding-left: 20px;
					padding-right: 20px;
					&.error{color: $red;}
				}
			}//form
		}//column
	}//Top





	.bottom{
		border-top: 1px white solid;
		padding: $vertical-padding-small 0px $vertical-padding-small;
		@include flex(flex,row,center,space-between);
		.logo{
			height: 40px;
			a {height:100%;}
			svg {
				height: 100%;
				position: relative;
				path {
					fill:white;
				}
			}
		}
		.menu{
			float: right;
			.social{
				float: left;
				a{
					margin-left: 30px;
					&:hover{
						img{
							@include transform(scale(1.1,1.1));
						}
					}
				}
				img{
					@include transition(transform 150ms 0ms ease);
					width: 20px;
					vertical-align: middle;
				}
			}
			.pages{
				float: right;
				li{
					float: left;
					margin-left: 30px;
					a{
						text-decoration: none;
						font-weight: 300;
						font-size: $small-FS;
						font-family: $barlow;
						&:hover{
							color: $red;
						}
					}
				}
			}
		}
	}//Bottom
	@media #{$tablet-portrait} {
		.content{
			@include width(12,11);
			@include ml(0,1);
		}
  }
	@media #{$phone}{
		.content {
			@include ml(1,0);
		}
		.top{
			flex-wrap:wrap;
			.column{
				margin-bottom: $vertical-padding-small;
			}
		}
		.bottom{
			.pages{
				display: none;
			}
		}
	}
}
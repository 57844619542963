//===============================================================/
//  Global Body
//===============================================================/

body{
	text-rendering: optimizeLegibility;
}

//===============================================================/
//  Global Font Settings
//===============================================================/

h1,h2,h3,h4,h5,h6,p{
	font-weight: normal;
}
h1{
	font-size: $h1_FS;
	margin: 0px auto 10px;
}
h2{
	font-size: $h2_FS;
}
h3{
	font-size: $h3_FS;
}
h4{
	font-size: $h4_FS;
}
h5{
	font-size: $h5_FS;
}
h6{
	font-size: $h6_FS;
}

p{
	margin: 0px auto 8px;
	line-height: 1.5;
}
ul{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
		padding: 0px;
		margin: 0 0 8px 0;
	}
}

a{
	text-decoration: none;
}




